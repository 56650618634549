import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Page from './Page';
import reportWebVitals from './reportWebVitals';
import Cookies from 'js-cookie';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Amplify, { Auth } from 'aws-amplify'

import {
  BrowserRouter as Router, Switch, Route
} from "react-router-dom";

Amplify.configure({
  Auth: {
      mandatorySignIn: true,
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_CM6XjHOxI',
      userPoolWebClientId: '2g1h7jks45eso7ol24s49oaq54'
  },
  API: {
    endpoints: [
      {
        name: "dodoApi",
        endpoint: "https://nkas4sd1d1.execute-api.eu-west-1.amazonaws.com/prod",
        custom_header: async () => { 
          return { Authorization: `Bearer ${(await Auth.currentSession()).getAccessToken().getJwtToken()}` }
        }
      }
    ]
  },
  oauth: {
    domain: 'dodo.auth.eu-west-1.amazoncognito.com',
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: 'https://dodo.sykes.cloud',
    redirectSignOut: 'https://dodo.sykes.cloud',
    responseType: 'code'
  }
})

Auth.currentAuthenticatedUser()
      .then(user => console.log(user))
      .catch(() => Auth.federatedSignIn({provider: 'Sykes'}));

const theme = createTheme({
  palette: {
    primary: {
      light: '#62727b',
      main: '#37474f',
      dark: '#102027',
      contrastText: '#fff',
    },
  },
});

ReactDOM.render(
    // add api context
    // add login context
    // .. if amplify doesn't provide these interfaces
    <Router>
        <Page />
    </Router>,
  document.getElementById('root')
);

// for measuring performance (can add console.log)
reportWebVitals();
