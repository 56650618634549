/**
 * @name PageViewService
 * @description Displays statistics about a specific service.
 * @author James Pearce
 */
import { Grid } from '@material-ui/core';
import React from 'react';

import {
  useParams
} from "react-router-dom";
import ServiceInfo from './ServiceInfo';

import Title from './Title';

export default function PageViewService() {
  let { id, name } = useParams();

  return (
    <div>
      <Title>{name}</Title>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ServiceInfo/>
        </Grid>
      </Grid>
    </div>
  );
}
