import API from "@aws-amplify/api";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import PowerButton from "./PowerButton";

export default function ServiceInfo() {
    const [info, setInfo] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        getData();
        async function getData() {
          const response = await API.get('dodoApi', '/services/'+id)
          setInfo(response)
          console.log(response)
        }
    },[])
  return (
    <Paper>
        <TableContainer>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align="right">{info.id}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Schedule</TableCell>
                        <TableCell align="right">{info.cron}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>State</TableCell>
                        <TableCell align="right"><PowerButton value={info.state} id={info.uuid}/></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    </Paper>
    );
}