/**
 * @name StatusChip
 * @description Converts a status ID to a Material UI chip component for use in the service list.
 * @author James Pearce
 */
import React from 'react';

import Chip from '@material-ui/core/Chip'
import CheckIcon from "@material-ui/icons/Check"
import CloseIcon from "@material-ui/icons/Close"
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

export default function StatusChip(props) {
  switch(props.value) {
    case '1':
      return (
        <Chip
          icon={<HourglassEmptyIcon />}
          size="small"
          label="Restarting"
          color='primary'
        />
      )
      break;
    case 'running':
      return (
        <Chip
          icon={<CheckIcon />}
          size="small"
          label="Online"
          color='primary'
          style={{backgroundColor:'#32B232'}}
        />
      )
      break;
    case 'stopped':
      return (
        <Chip
          icon={<CloseIcon />}
          size="small"
          label="Offline"
          color="secondary"
        />
      )
      break;
  }
  return (<>?</>)
}
