/**
 * @name PageRouter
 * @description 
 * @author James Pearce
 */
import React from 'react';
import { Switch, Route } from "react-router-dom";
import Loading from './pages/Loading';
import PageListServices from './pages/service/PageListServices';
import PageViewService from './pages/service/PageViewService';

export default function PageRouter() {
    return (
        <Switch>
            <Route path="/loading">
                <Loading />
            </Route>
            <Route path="/services/:id/:name">
                <PageViewService />
            </Route>
            <Route path="/services">
                <PageListServices />
            </Route>
        </Switch>
    );
}