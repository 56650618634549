/**
 * @name PowerButton
 * @description
 * @author James Pearce
 */
import React, { useEffect, useState } from 'react';

import Chip from '@material-ui/core/Chip'
import CheckIcon from "@material-ui/icons/Check"
import CloseIcon from "@material-ui/icons/Close"
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import API from '@aws-amplify/api';


export default function PowerButton(props) {
  const [state, setState] = useState(props.value)
  console.log(state)
  let powerOn = async () => {
    let response = await API.put('dodoApi', '/services/'+props.id)
    setState('running')
  }
  useEffect(()=>{}, [state])

  switch(state ? state : props.value) {
    case '1':
      return (
        <Chip
          icon={<HourglassEmptyIcon />}
          size="small"
          label="Restarting"
          color='primary'
        />
      )
      break;
    case 'running':
      return (
        <Chip
          icon={<CheckIcon />}
          size="small"
          label="Online"
          color='primary'
          style={{backgroundColor:'#32B232'}}
        />
      )
      break;
    case 'stopped':
      return (
        <Chip
          size="small"
          label="Offline"
          color="secondary"
          onDelete={powerOn}
          deleteIcon={<PowerSettingsNewIcon />}
        />
      )
      break;
  }
  return (<>?</>)
}
