/**
 * @name Loading
 * @description A loading overlay. Uses Lottie to display an animated Sykes logo/Dodo logo
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Lottie from "lottie-react";
import SykesDodo from '../ani/SykesDodo.json';
import { Paper } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: "center",
    height: '100%',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // fixes ie11 issue apparently
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Loading() {
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      <Lottie style={{"div g": {fill: "#CCC"}, height: '100px'}} animationData={SykesDodo}></Lottie>
    </Container>
  );
}
