/**
 * @name PageListServices
 * @description Features a filterable DataGrid listing EC2/ECS instances.
 * @author James Pearce
 */
import React, { useState, useEffect } from 'react';
import StatusChip from './StatusChip'
import { DataGrid, GRID_ROWS_CLEARED } from '@material-ui/data-grid';
import { useHistory } from "react-router-dom";
import { API } from 'aws-amplify'
import Loading from '../Loading';

const columns = [
  { field: 'type', headerName: 'Type', width: 70 },
  { field: 'name', headerName: 'Name', flex: 1 },
  { field: 'account', headerName: 'Account', width: 140 },
  {
    field: 'state',
    headerName: 'Status',
    description: '',
    sortable: false,
    width: 160,
    renderCell: (params) => {return <StatusChip value={params.getValue(params.id, 'state')}/>},
  },
  { field: 'last', headerName: 'Last Restart', width: 300, type: 'dateTime' },
];

export default function PageListServices() {
  const history = useHistory();
  const [rows, setRows] = useState([]);
  const serviceNavigate = (params) => {
    history.push("/services/"+params.row.uuid+"/"+params.row.name)
  }

  useEffect(() => {
    getData();
    async function getData() {
      const response = await API.get('dodoApi', '/services')
      setRows(response)
      console.log(response)
    }
  },[])

  return (
    <div style={{ height: '100%', width: '100%' }}>
      {rows.length > 0 ?
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          onCellDoubleClick={serviceNavigate}
          filterMode="client"
          //onFilterModelChange={handleFilterModelChange}
        />
        :
        <Loading/>
      }
    </div>
  );
}
